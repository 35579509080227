import moment from 'moment';
import { ReservationType } from '../types/api';

export const TOKEN = 'authToken';

export const ADMIN = 'admin';
export const OWNER = 'owner';
export const DRIVER = 'driver';

export const USER_ROLES = [ADMIN, OWNER, DRIVER];

export const CAR_PENDING = 'pending';
export const CAR_ACTIVE = 'active';
export const CAR_DEACTIVATED = 'deactived';
export const CAR_COMING_SOON = 'coming_soon';

export const CAR_MANUAL = 'Manual';
export const CAR_AUTOMATIC = 'Automatic';

export const CAR_TRACTION_FRONT = 'Fata';
export const CAR_TRACTION_REAR = 'Spate';
export const CAR_TRACTION_ALL = '4X4';

export const CAR_FUEL_GAS = 'Benzina';
export const CAR_FUEL_DIESEL = 'Diesel';
export const CAR_FULE_GAS_GPL = 'Benzina/GPL';
export const CAR_FUEL_HYBRID_GAS = 'Hybrid/Benzina';
export const CAR_FUEL_HYBRID_PLUG = 'Plug-in Hybrid';
export const CAR_FUEL_ELECTRIC = 'Electric';

export const CAR_TYPE_HATCHBACK = 'Hatchback';
export const CAR_TYPE_SEDAN = 'Sedan';
export const CAR_TYPE_BREAK = 'Break';
export const CAR_TYPE_COUPE = 'Coupe';
export const CAR_TYPE_CROSS = 'Crossover';
export const CAR_TYPE_SUV = 'SUV';
export const CAR_TYPE_MONO = 'Monovolum';
export const CAR_TYPE_PICKUP = 'Pickup';
export const CAR_TYPE_CONVERTIBLE = 'Convertible';

export const CAR_STATUS = [
  CAR_PENDING,
  CAR_ACTIVE,
  CAR_DEACTIVATED,
  CAR_COMING_SOON,
];

export const STATUS_COLOR_GREEN = '#60c72e';
export const STATUS_COLOR_ORANGE = '#ff8a00';
export const STATUS_COLOR_RED = '#ff0000';

export enum StatusColorType {
  STATUS_COLOR_GREEN = '#60c72e',
  STATUS_COLOR_ORANGE = '#ff8a00',
  STATUS_COLOR_RED = '#ff0000',
}

export const PENDING = 'pending';
export const PENDING_PAYMENT = 'pending_payment';
export const CONFIRMED = 'confirmed';
export const CANCELED = 'canceled';
export const REFUSED_BY_CLIENT = 'refused_client';
export const REFUSED_BY_OWNER = 'refused_owner';

export const CAR_EVENT_ITP = 'ITP';
export const CAR_EVENT_VINIETA = 'VINIETA';
export const CAR_EVENT_INSURANCE = 'RCA';
export const CAR_EVENT_CASCO = 'CASCO';

export const RESERVATION_STATUS = [
  PENDING,
  PENDING_PAYMENT,
  CONFIRMED,
  CANCELED,
];

export const IS_DEV_ENV = false
export const IS_PROD_ENV = true;

const isRunningLocal = process.env.REACT_APP_LOCAL === 'true';

export const PARTNER_PLATFORM_URL = IS_PROD_ENV ? 'https://partener.masinilacheie.ro/' : 'https://stage-partener.masinilacheie.ro/';

export const BASE_URL = isRunningLocal
  ? 'http://localhost:5001/api'
  : IS_PROD_ENV
    ? 'https://api.masinilacheie.ro/api'
    : 'https://stage-api.masinilacheie.ro/api';

export const HOME_URL = 'https://masinilacheie.ro';
export const GTAG_EVENT_CONVERSION_ID = 'AW-10895272414/XNvJCObr4d8YEN7Tosso';

export enum PlatformType {
  Driver = 'driver',
  Owner = 'owner',
  Admin = 'admin',
}

export const PLATFORM_TYPE: PlatformType =
  (process.env.REACT_APP_PLATFORM_TYPE as PlatformType) || PlatformType.Driver;

export const SHOW_OWNER_SWITCH =
  process.env.REACT_APP_OWNER_SWITCH === 'true' &&
  PLATFORM_TYPE === PlatformType.Owner;

export const STRIPE_PUB_KEY = IS_PROD_ENV
  ? 'pk_live_51Jsaq0CYkNHo3wX2I5uwmaOpBSadOUDXoQfXQMgsz0IeyB6HMPfgsqkBM9Ry9T2SQchOexJ5qVRRQSUsYG6ZVMF700ZcpaTesB'
  : 'pk_test_51Jsaq0CYkNHo3wX2iWOX4qjOiNuUbXvlMZ3TYifVC6dZDy8fWuA6Xh7mXrwFBF2W5w1iSnUFJQ0tnRNQgojYdmSr00shdbZg7t';

export const LIST_CAR_LINK =
  'https://docs.google.com/forms/d/e/1FAIpQLSe1v5q10vPpU-FUmN9au9rq1xSCM5fqLsfb_LrH-lQ71omlhQ/viewform?usp=sf_link';

export const MIXPANEL_TOKEN =
  PLATFORM_TYPE === PlatformType.Driver
    ? '6877c2c715dfa046f7bf4c71a8e92e20'
    : 'dbbac86f4929d09f8be1479dd7378bce';

export const MINUTE_S = 60;
export const HOUR_S = 60 * MINUTE_S;
export const DAY_S = 24 * HOUR_S;

export const BEGIN_DATE = moment().startOf('day').add(1, 'days').toDate();
export const END_DATE = moment(BEGIN_DATE).add(12, 'months').toDate();

export const BACKGROUND_COLOR: Record<ReservationType, string> = {
  [ReservationType.Driver]: '#FFFFFF',
  [ReservationType.Owner]: '#FFFFFF',
  [ReservationType.Service]: '#FFD9AA',
  [ReservationType.Personal]: '#FFDBE8',
};

export const BACKGROUND_COLOR_AGENDA: Record<ReservationType, string> = {
  [ReservationType.Driver]: '#D9D4E6',
  [ReservationType.Owner]: '#D9D4E6',
  [ReservationType.Service]: '#FFD9AA',
  [ReservationType.Personal]: '#FFDBE8',
};

export const DISTANCE_KM_VALUES = [
  100, 150, 200, 250, 300, 400, 500, 750, 1000,
].map((x) => ({ value: `${x}`, name: `${x}` }));

export const DAYS_TO_RENT = new Array(30)
  .fill(0)
  .map((_, index) => index + 1)
  .map((x) => ({ value: `${x}`, name: `${x}` }));

// from -50 to 125 => 176 total numbers
export const MONTHS_INCREASE = new Array(176)
  .fill(0)
  .map((_, index) => index - 50)
  .reverse()
  .map((x) => ({
    value: `${x}`,
    name: `${x > 0 ? '+' : ''}${x}%`,
  }));

export const MIN_DISCOUNT = 1;
export const MAX_DISCOUNT = 70;

export const RECOMMENDED_DISCOUNTS: Array<{ days: number; discount: number }> =
  [
    {
      days: 30,
      discount: 50,
    },
    {
      days: 14,
      discount: 45,
    },
    {
      days: 7,
      discount: 40,
    },
    {
      days: 4,
      discount: 30,
    },
    {
      days: 2,
      discount: 20,
    },
  ];

export const MAX_MINUTES = 24 * 60 - 30;
export const MIN_MINUTES = 0;

export const MONTHS_SHORT = [
  'Ian',
  'Feb',
  'Mar',
  'Apr',
  'Mai',
  'Iun',
  'Iul',
  'Aug',
  'Sep',
  'Oct',
  'Noi',
  'Dec',
];

export const EXTRA_KM = [
  { value: '0', name: 'Fara extra km' },
  { value: '1', name: '100 extra km' },
  { value: '2', name: '200 extra km' },
  { value: '3', name: '300 extra km' },
  { value: '4', name: '400 extra km' },
  { value: '5', name: '500 extra km' },
  { value: '6', name: '500 extra km' },
  { value: '7', name: '500 extra km' },
  { value: '8', name: '500 extra km' },
  { value: '9', name: '500 extra km' },
  { value: '10', name: '1000 extra km' },
  { value: '12', name: '1200 extra km' },
  { value: '15', name: '1500 extra km' },
  { value: '20', name: '2000 extra km' },
  { value: '30', name: '3000 extra km' },
  { value: '50', name: '5000 extra km' },
];
