import classNames from 'classnames';
import React, { useMemo } from 'react';
import { CarSettings } from '../../../types/api';
import {
  MCCard,
  MCCell,
  MCCheckbox,
  MCDivider,
  MCHeadline,
  MCParagraph,
  MCSpacing,
} from '../../../_shared/components';
import { InputPriceField } from '../../../_shared/components/InputField/InputField';
import { useOwnerAnalytics } from '../../hooks';
import styles from './Prices.module.css';

export interface PriceGuaranteesProps {
  basePrices: CarSettings;
  setBasePrices: (basePrices: CarSettings) => void;
}

const NO_ASSURANCE = 'Oferta de baza';
const LIMITED_ASSURANCE = 'Asigurare limitata';
const FULL_ASSURANCE = 'Asigurare Full';

export function PriceGuarantees({
  basePrices,
  setBasePrices,
}: PriceGuaranteesProps) {
  const { trackEvent } = useOwnerAnalytics();
  const guarantees = useMemo(() => {
    return [
      {
        name: NO_ASSURANCE,
        active: basePrices.guarantees.integral.active,
        onSetActive: () => {},
        deposit: basePrices.guarantees.integral.guarantee,
        onSetDeposit: () => {},
        perDayAssurancePrice: 0,
        setPerDayAssurancePrice: (price: number) => {},
      },
      {
        name: LIMITED_ASSURANCE,
        active: basePrices.guarantees.reduced.active,
        onSetActive: (active: boolean) => {
          trackEvent('Prices_GuaranteeActive_Check', {
            checked: active,
            name: LIMITED_ASSURANCE,
          });
          setBasePrices({
            ...basePrices,
            guarantees: {
              ...basePrices.guarantees,
              reduced: { ...basePrices.guarantees.reduced, active },
            },
          });
        },
        deposit: basePrices.guarantees.reduced.guarantee,
        onSetDeposit: (guarantee: number) => {
          trackEvent('Prices_GuaranteeValue_Type', {
            amount: guarantee,
            name: LIMITED_ASSURANCE,
          });
          setBasePrices({
            ...basePrices,
            guarantees: {
              ...basePrices.guarantees,
              reduced: { ...basePrices.guarantees.reduced, guarantee },
            },
          });
        },
        perDayAssurancePrice:
          basePrices.guarantees.reduced.assurancePricePerDay,
        setPerDayAssurancePrice: (assurancePricePerDay: number) => {
          trackEvent('Prices_InsuranceValue_Type', {
            amount: assurancePricePerDay,
            name: LIMITED_ASSURANCE,
          });
          setBasePrices({
            ...basePrices,
            guarantees: {
              ...basePrices.guarantees,
              reduced: {
                ...basePrices.guarantees.reduced,
                assurancePricePerDay,
              },
            },
          });
        },
        recommendation: '(recomandat 50 - 150 Ron)',
      },
      {
        name: FULL_ASSURANCE,
        active: basePrices.guarantees.minimal.active,
        onSetActive: (active: boolean) => {
          trackEvent('Prices_GuaranteeActive_Check', {
            checked: active,
            name: FULL_ASSURANCE,
          });
          setBasePrices({
            ...basePrices,
            guarantees: {
              ...basePrices.guarantees,
              minimal: { ...basePrices.guarantees.minimal, active },
            },
          });
        },
        deposit: basePrices.guarantees.minimal.guarantee,
        onSetDeposit: (guarantee: number) => {
          trackEvent('Prices_GuaranteeValue_Type', {
            amount: guarantee,
            name: FULL_ASSURANCE,
          });
          setBasePrices({
            ...basePrices,
            guarantees: {
              ...basePrices.guarantees,
              minimal: { ...basePrices.guarantees.minimal, guarantee },
            },
          });
        },
        perDayAssurancePrice:
          basePrices.guarantees.minimal.assurancePricePerDay,
        setPerDayAssurancePrice: (assurancePricePerDay: number) => {
          trackEvent('Prices_InsuranceValue_Type', {
            amount: assurancePricePerDay,
            name: FULL_ASSURANCE,
          });
          setBasePrices({
            ...basePrices,
            guarantees: {
              ...basePrices.guarantees,
              minimal: {
                ...basePrices.guarantees.minimal,
                assurancePricePerDay,
              },
            },
          });
        },
        recommendation: '(recomandat 100 - 250 Ron)',
      },
    ];
  }, [basePrices, setBasePrices, trackEvent]);

  return (
    <>
      <MCSpacing />
      <MCSpacing />
      <div>
        <MCHeadline>Optiuni de asigurari</MCHeadline>
      </div>
      <MCSpacing />
      <MCSpacing />
      {guarantees.map((guarantee, index) => {
        return (
          <React.Fragment key={guarantee.name}>
            <MCCard key={guarantee.name} style={{ padding: 32 }}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <MCCell>
                  <MCHeadline>{guarantee.name}</MCHeadline>
                </MCCell>
                <MCCell>
                  <div
                    className={classNames({
                      [styles.disabled]: index === 0,
                    })}
                  >
                    <MCCheckbox
                      value={guarantee.active}
                      onChange={guarantee.onSetActive}
                    >
                      <MCParagraph>
                        Optiune {guarantee.active ? 'activa' : 'inactiva'}
                      </MCParagraph>
                    </MCCheckbox>
                  </div>
                </MCCell>
                <MCCell>
                  <div
                    style={{ width: 150 }}
                    className={classNames({
                      [styles.disabled]: !guarantee.active,
                    })}
                  >
                    <div>
                      <MCParagraph bold>Garantie/depozit</MCParagraph>
                    </div>
                    <MCSpacing amount={8} />
                    <div
                      style={{
                        display: 'flex',
                        width: '100%',
                        flexDirection: 'row',
                        justifyContent: 'space-evenly',
                      }}
                    >
                      <div style={{ width: 100 }}>
                        {index === 0 && (
                          <MCParagraph bold>
                            {guarantee.deposit / 100}
                          </MCParagraph>
                        )}
                        {index !== 0 && (
                          <InputPriceField
                            value={guarantee.deposit}
                            type="number"
                            onChange={guarantee.onSetDeposit}
                          />
                        )}
                      </div>
                      <MCSpacing vertical={false} />
                      <div>
                        <MCParagraph bold>Ron</MCParagraph>
                      </div>
                    </div>
                  </div>
                </MCCell>
                <MCCell flex={0.2} />
                <MCCell flex={2}>
                  <div
                    className={classNames({
                      [styles.disabled]: !guarantee.active,
                    })}
                  >
                    <div>
                      <MCParagraph bold>Cost asigurare / zi</MCParagraph>
                    </div>
                    <MCSpacing amount={8} />
                    <div
                      style={{
                        display: 'flex',
                        width: '100%',
                        flexDirection: 'row',
                        justifyContent: 'space-evenly',
                      }}
                    >
                      <div style={{ width: 100 }}>
                        {index === 0 && (
                          <MCParagraph bold>
                            {guarantee.perDayAssurancePrice / 100}
                          </MCParagraph>
                        )}
                        {index !== 0 && (
                          <InputPriceField
                            value={guarantee.perDayAssurancePrice}
                            type="number"
                            onChange={guarantee.setPerDayAssurancePrice}
                          />
                        )}
                      </div>
                      <MCSpacing vertical={false} />
                      <div>
                        <MCParagraph bold>
                          Ron {guarantee.recommendation}
                        </MCParagraph>
                      </div>
                    </div>
                  </div>
                </MCCell>
              </div>
              <div
                style={{ width: '100%' }}
                className={classNames({
                  [styles.disabled]: !guarantee.active,
                })}
              >
                <MCDivider fullWidth color={'#422680'} />
              </div>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
                className={classNames({
                  [styles.disabled]: !guarantee.active,
                })}
              >
                {basePrices.rentalOffers.map((offer, offerIndex) => {
                  const nextOffer =
                    offerIndex + 1 < basePrices.rentalOffers.length
                      ? basePrices.rentalOffers[offerIndex + 1]
                      : null;
                  const label = !nextOffer
                    ? `${offer.days}+ zile`
                    : offerIndex === 0
                    ? `${offer.days} zile`
                    : `${offer.days}${
                        nextOffer.days - offer.days > 1
                          ? `-${nextOffer.days - 1}`
                          : ''
                      } zile`;

                  const discountPercentage =
                    1 -
                    offer.priceIntegral /
                      basePrices.rentalOffers[0].priceIntegral;

                  const assuranceDiscountValue =
                    guarantee.perDayAssurancePrice * discountPercentage;

                  const finalValue = Math.round(
                    (guarantee.perDayAssurancePrice - assuranceDiscountValue) /
                      100
                  );

                  return (
                    <div key={offerIndex}>
                      <div>
                        <MCParagraph bold>{label}</MCParagraph>
                      </div>
                      <div>
                        <MCParagraph>{finalValue} Ron / zi</MCParagraph>
                      </div>
                    </div>
                  );
                })}
              </div>
            </MCCard>
            <MCSpacing />
          </React.Fragment>
        );
      })}
      <MCSpacing />
      <MCSpacing />
      <MCParagraph fontSize={18} bold>
        <MCParagraph fontSize={18} color="#F54952" bold>
          *IMPORTANT
        </MCParagraph>{' '}
        - Aceste setari sunt avansate (optionale) si sunt recomandate doar daca
        valoarea masinii trece de 15 000 Euro. Inainte sa faceti modificari,
        asigurati-va ca ati inteles formula de calcul sau cereti asistenta
        echipei Masinilacheie.
      </MCParagraph>
      <MCSpacing />
      <MCSpacing />
      <MCParagraph fontSize={14} bold>
        Costul asigurari se seteaza pentru o zi si in functie de discount-ul
        aplicat in tab-ul “Setari de preturi” se va calcula automat pentru
        restul ofertelor de pret.
      </MCParagraph>
      <MCSpacing />
      <MCParagraph bold fontSize={14}>
        Exemplu:
        <br />
        Cost masina 200 Ron / zi pentru 1 zi de inchiriere.
        <br />
        Cost masina 150 Ron / zi pentru 4 zile de inchiriere. (25% discount)
        <br />
        Cost Asigurare Limitata setat 50 Ron / zi pentru 1 zi inchiriere. <br />
        Cost Asigurare Limitata calculat 37,5 Ron / zi pentru 4 zile inchiriere.
        (25% discount)
      </MCParagraph>
      <MCSpacing />
      <MCParagraph bold fontSize={14}>
        Pretul pe zi final este rezultatul final din setarile de preturi pe care
        le-ati facut in tab-ul “Setari de preturi” + costul asigurarii in
        functie de garantia aleasa.
      </MCParagraph>
      <MCSpacing />
      <MCParagraph bold fontSize={14}>
        Exemplu: <br />
        Aveti pentru masina Opel Insignia optiunile de asigurare active cu un
        cost de 50ron/zi pentru protectie limitata si 100ron/zi pentru protectie
        full.
        <br />
        Clientul doreste sa inchirieze masina Opel Insignia pentru 7 zile cu un
        cost de 150 Ron / Zi. Rezulta 3 optiuni:
        <br />
        Fara asigurare si plateste 7 * 150 Ron = 1050 Ron
        <br />
        Asigurare Limitata si plateste 7 * 150 Ron + 7 * 50 Ron = 1400 Ron
        <br />
        Asigurare Full si plateste 7 * 150 Ron + 7 * 100 Ron = 1750 Ron <br />
      </MCParagraph>
      <MCSpacing />
      <MCSpacing />
    </>
  );
}
