import mixpanel from 'mixpanel-browser';

type BaseMap = Record<string, object | undefined>;

export class GenericAnalytics<EventMap extends BaseMap> {
  public trackEvent = <EventName extends keyof EventMap & string>(
    event: EventName,
    ...params: EventMap[EventName] extends undefined
      ? []
      : [EventMap[EventName]]
  ) => {
    mixpanel.track(event, params[0]);
  };

  public withEvent = <EventName extends keyof EventMap & string>(
    event: EventName,
    ...params: EventMap[EventName] extends undefined
      ? []
      : [EventMap[EventName]]
  ) => {
    return <T extends (...args: any[]) => any>(func: T) => {
      return (...funcArgs: Parameters<T>) => {
        mixpanel.track(event, params);
        return func(...funcArgs);
      };
    };
  };

  public identify = ({
    email,
    name,
    phone,
  }: {
    email: string;
    name?: string;
    phone?: string;
  }) => {
    const distinctId = mixpanel.get_distinct_id();
    console.log({ distinct_id: distinctId });
    if (distinctId !== email) {
      mixpanel.alias(distinctId, email);
    }

    mixpanel.identify(email);
    mixpanel.people.set({
      $email: email,
      // $name: name,
      $phone: phone,
    });
  };
}
